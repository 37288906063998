/*
 * Home Styles
 */

.mi-home{
  &-area{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 1;

    .react-image-light {
      height: 600px;
      bottom: -1%;
      left: 1%;
      position: absolute;
      opacity: .03;
      z-index: 0;
    }

    .react-image-light:hover {
      opacity: .5;
    }

    .react-image-dark {
      display: none;
    }

    .js-image-light {
      position: absolute;
      top: -4%;
      left: -5%;
      opacity: .03;
      transition: 0.3s;
      z-index: 3;
    }

    .js-image-light:hover {
      opacity: .5;
    }


    .js-image-dark{
      display: none;
    }

    .css-image-light {
      position: absolute;
      bottom: -10%;
      right: -5%;
      opacity: 0.03;
      transition: 0.3s;
      z-index: 4;
    }

    .css-image-light:hover {
      opacity: 0.6;
    }

    .css-image-dark {
      display: none;
    }

    .node-image-light {
      position: absolute;
      top: 6%;
      right: 15%;
      opacity: 0.03;
      transition: 0.3s;
      z-index: 4;
    }

    .node-image-light:hover {
      opacity: 0.4;
    }

    .node-image-dark {
      display: none;
    }
  }

  &-particle{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &-content{
    text-align: center;
    padding: 50px 80px;

    h1{
      font-weight: 700;
    }

    p{
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 2rem;
    }

    .mi-socialicons{
      margin-top: 20px;
    }
  }

  @media #{$layout-laptop}{
    &-content{
      padding: 50px 0px;
    }
  }

  @media #{$layout-notebook}{
    &-content{
      padding: 50px 0px;
    }
  }

  @media #{$layout-tablet}{
    &-content{
      padding: 50px 0px;

      p{
        font-size: 1.15rem;
        line-height: 1.75rem;
      }
    }
  }

  @media #{$layout-mobile-lg}{
    &-content{
      padding: 50px 0px;

      p{
        font-size: 1.08rem;
        line-height: 1.55rem;
      }
    }
  }

  @media #{$layout-mobile-sm}{
    &-content{
      h1{
        span{
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .react-image-light,
      .css-image-light,
      .js-image-light,
      .node-image-light {
        display: none;
      }
}

// ++++++++++++++++++++
// Eyes
// ++++++++++++++++++++

.spy {
  margin: 20px auto;
  width: 60px;
  height: 30px;
  position: absolute;
  bottom: 30%;
  right: 1%;
}

.eye {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
  background-color: #fff; 
  margin: 0px 6px;
  animation: blink 6s infinite;
  box-shadow: 0 0 10px 0px #fff;
}

@keyframes blink {
  0%  { height: 10px; margin-top: 0px;}
  1%  { height: 1px;  margin-top: -4px;}
  2%  { height: 10px; margin-top: 0px;}

}
.spy2 {
  margin: 20px auto;
  width: 60px;
  height: 30px;
  position: absolute;
  top: 2%;
  right: 45%;
}

.eye2 {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%; 
  background-color: #fff; 
  margin: 0px 6px;
  animation: blink2 5s infinite;
  animation-delay: 2s;
  box-shadow: 0 0 10px 0px #fff;
}

@keyframes blink2 {
  0%  { height: 7px; margin-top: 0px;}
  1%  { height: 1px;  margin-top: -4px;}
  2%  { height: 7px; margin-top: 0px;}
}
.spy3 {
  margin: 20px auto;
  width: 60px;
  height: 30px;
  position: absolute;
  bottom: 13%;
  left: 20%;
}

.eye3 {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%; 
  background-color: #fff; 
  margin: 0px 5px;
  animation: blink2 5s infinite;
  animation-delay: 2s;
  box-shadow: 0 0 10px 0px #fff;
}

@keyframes blink3 {
  0%  { height: 2px; margin-top: 0px;}
  1%  { height: .2px;  margin-top: -2px;}
  2%  { height: 2px; margin-top: 0px;}
}